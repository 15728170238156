.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .6;
  font-size: 0.75rem; }

.hr-text:before {
  content: '';
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(rgba(24, 28, 33, 0.6)), to(transparent));
  background: linear-gradient(to right, transparent, rgba(24, 28, 33, 0.6), transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px; }

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  padding: 0 .5em;
  line-height: 1.5em;
  background-color: #fff; }

#card-body {
  overflow-y: auto;
  height: 100vh; }

div.media-body {
  font-size: 0.85rem !important;
  line-height: 1.25rem !important; }

table.chit-table th:last-child {
  text-align: right; }

table.chit-table td:last-child {
  text-align: right; }

table.chit-table.edit th:last-child {
  text-align: right; }

table.chit-table.edit td:last-child {
  text-align: right; }

table.chit-table.edit td:nth-child(1) {
  width: 60%; }

table.chit-table.edit td:nth-child(2) {
  width: 30%; }

table.chit-table.edit td:nth-child(3) {
  width: 10%; }

table.chit-table.edit th:nth-child(2) {
  text-align: right; }

table.chit-table.edit td:nth-child(2) {
  text-align: right; }

.bg-lighter td {
  font-weight: bold !important; }

tr.chit-totals {
  background-color: inherit;
  font-size: 110%;
  font-weight: bold; }

.form-label.hide {
  display: none !important; }

body {
  background: #f8f8f8; }

.text-primary {
  color: #02BC77 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #018a57 !important; }

.bg-primary {
  background-color: #02BC77 !important; }

a.bg-primary:hover, a.bg-primary:focus {
  background-color: #02b371 !important; }

.bg-primary-dark {
  background-color: #02a96b !important; }

a.bg-primary-dark:hover, a.bg-primary-dark:focus {
  background-color: #02a166 !important; }

.bg-primary-darker {
  background-color: #02a065 !important; }

a.bg-primary-darker:hover, a.bg-primary-darker:focus {
  background-color: #029860 !important; }

html:not([dir=rtl]) .border-primary, html[dir=rtl] .border-primary {
  border-color: #02BC77 !important; }

.badge-primary {
  background-color: #02BC77;
  color: #fff; }

.badge-primary[href]:hover, .badge-primary[href]:focus {
  background-color: #02b371;
  color: #fff;
  text-decoration: none; }

.btn .badge-primary {
  background-color: #02BC77 !important;
  color: #fff !important; }

.badge-outline-primary {
  background-color: transparent;
  -webkit-box-shadow: 0 0 0 1px #02BC77 inset;
          box-shadow: 0 0 0 1px #02BC77 inset;
  color: #02BC77; }

.badge-outline-primary[href]:hover, .badge-outline-primary[href]:focus {
  color: #02BC77;
  text-decoration: none; }

.btn .badge-outline-primary {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1px #02BC77 inset !important;
          box-shadow: 0 0 0 1px #02BC77 inset !important;
  color: #02BC77 !important; }

.page-item.active .page-link, .page-item.active .page-link:hover, .page-item.active .page-link:focus,
.pagination li.active > a:not(.page-link),
.pagination li.active > a:not(.page-link):hover,
.pagination li.active > a:not(.page-link):focus {
  border-color: #02BC77 !important;
  background-color: #02BC77 !important;
  color: #fff !important; }

.progress-bar {
  background-color: #02BC77;
  color: #fff; }

.list-group-item-primary {
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #e6f8f1;
  color: #5c6360; }

a.list-group-item-primary,
button.list-group-item-primary {
  color: #5c6360; }
  a.list-group-item-primary:hover, a.list-group-item-primary:focus,
  button.list-group-item-primary:hover,
  button.list-group-item-primary:focus {
    background-color: #e4f6ef;
    color: #5c6360; }
  a.list-group-item-primary.active,
  button.list-group-item-primary.active {
    border-color: #02BC77;
    background-color: #02BC77;
    color: #5c6360; }

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  border-color: #02BC77;
  background-color: #02BC77;
  color: #fff; }

.alert-primary {
  border-color: #cce6dd;
  background-color: #d9f5eb;
  color: #57625e; }
  .alert-primary hr {
    border-top-color: #cce6dd; }
  .alert-primary .close,
  .alert-primary .alert-link {
    color: #57625e; }

.alert-dark-primary {
  background-color: #02BC77;
  color: #fff; }
  .alert-dark-primary hr {
    border-top-color: rgba(255, 255, 255, 0.2); }
  .alert-dark-primary .close,
  .alert-dark-primary .alert-link {
    color: #fff; }

.tooltip-primary .tooltip-inner, .tooltip-primary > .tooltip .tooltip-inner, .ngb-tooltip-primary + ngb-tooltip-window .tooltip-inner {
  background: #02BC77;
  color: #fff; }

.tooltip-primary.bs-tooltip-top .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="top"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-top .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-top .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #02BC77; }

.tooltip-primary.bs-tooltip-right .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-right .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-right .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #02BC77; }

.tooltip-primary.bs-tooltip-bottom .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-bottom .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-bottom .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #02BC77; }

.tooltip-primary.bs-tooltip-left .arrow::before, .tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-left .arrow::before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-left .arrow::before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #02BC77; }

.popover-primary, .popover-primary > .popover, .ngb-popover-primary + ngb-popover-window {
  border-color: transparent;
  background: #02BC77; }
  .popover-primary .popover-header, .popover-primary > .popover .popover-header, .ngb-popover-primary + ngb-popover-window .popover-header {
    border-color: rgba(255, 255, 255, 0.2);
    background: transparent;
    color: #fff; }
  .popover-primary .popover-body, .popover-primary > .popover .popover-body, .ngb-popover-primary + ngb-popover-window .popover-body {
    background: transparent;
    color: #fff; }
  .popover-primary .arrow::before, .popover-primary > .popover .arrow::before, .ngb-popover-primary + ngb-popover-window .arrow::before {
    border-color: transparent !important; }
  .popover-primary.bs-popover-top .arrow::after, .popover-primary.bs-popover-auto[x-placement^="top"] .arrow::after, .popover-primary > .popover.bs-popover-top .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="top"] .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-top .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #02BC77; }
  .popover-primary.bs-popover-right .arrow::after, .popover-primary.bs-popover-auto[x-placement^="right"] .arrow::after, .popover-primary > .popover.bs-popover-right .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="right"] .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-right .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #02BC77; }
  .popover-primary.bs-popover-bottom .arrow::after, .popover-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after, .popover-primary > .popover.bs-popover-bottom .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-bottom .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #02BC77; }
  .popover-primary.bs-popover-left .arrow::after, .popover-primary.bs-popover-auto[x-placement^="left"] .arrow::after, .popover-primary > .popover.bs-popover-left .arrow::after, .popover-primary > .popover.bs-popover-auto[x-placement^="left"] .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-left .arrow::after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #02BC77; }

.table .table-primary,
.table .table-primary > th,
.table .table-primary > td {
  border-color: rgba(0, 0, 0, 0.035);
  background-color: #e6f8f1;
  color: #5c6360; }

.table-hover .table-primary:hover,
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #e4f6ef; }

.btn-primary {
  border-color: transparent;
  background: #02BC77;
  color: #fff; }
  .btn-primary:hover {
    border-color: transparent;
    background: #02b371;
    color: #fff; }
  .btn-primary:focus, .btn-primary.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(2, 188, 119, 0.4);
            box-shadow: 0 0 0 2px rgba(2, 188, 119, 0.4); }
  .btn-primary.disabled, .btn-primary:disabled {
    border-color: transparent !important;
    background: #02BC77 !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    color: #fff !important; }

.btn-primary .badge {
  background: #fff;
  color: #02BC77; }

.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  border-color: transparent;
  background: #02a468;
  -webkit-box-shadow: none;
          box-shadow: none; }

.btn-group .btn-primary,
.input-group-prepend .btn-primary,
.input-group-append .btn-primary {
  border-right: 1px solid #02a468;
  border-left: 1px solid #02a468; }

.btn-outline-primary {
  border-color: #02BC77;
  background: transparent;
  color: #02BC77; }
  .btn-outline-primary:hover {
    border-color: transparent;
    background: #02BC77;
    color: #fff; }
    .btn-outline-primary:hover .badge {
      background: #fff;
      color: #02BC77; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 2px rgba(2, 188, 119, 0.4);
            box-shadow: 0 0 0 2px rgba(2, 188, 119, 0.4); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    border-color: #02BC77 !important;
    background: transparent !important;
    color: #02BC77 !important; }

.btn-outline-primary .badge {
  background: #02BC77;
  color: #fff; }

.btn-outline-primary:active,
.btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
  border-color: transparent;
  background: #02b371;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .btn-outline-primary:active .badge,
  .btn-outline-primary.active .badge,
  .show > .btn-outline-primary.dropdown-toggle .badge {
    background: #fff;
    color: #02BC77; }

.dropdown-item:not(.disabled).active,
.dropdown-item:not(.disabled):active {
  background-color: #02BC77;
  color: #fff; }

.dropdown-menu > li:not(.disabled) > a:not(.dropdown-item):active,
.dropdown-menu > li.active:not(.disabled) > a:not(.dropdown-item) {
  background-color: #02BC77;
  color: #fff; }

.nav-pills .nav-link.active, .nav-pills .nav-link.active:hover, .nav-pills .nav-link.active:focus {
  background-color: #02BC77;
  color: #fff; }

.tabs-alt.nav-tabs .nav-link.active, .tabs-alt.nav-tabs .nav-link.active:hover, .tabs-alt.nav-tabs .nav-link.active:focus,
.tabs-alt > .nav-tabs .nav-link.active,
.tabs-alt > .nav-tabs .nav-link.active:hover,
.tabs-alt > .nav-tabs .nav-link.active:focus {
  -webkit-box-shadow: 0 -2px 0 #02BC77 inset;
          box-shadow: 0 -2px 0 #02BC77 inset; }

.custom-control .custom-control-input:focus ~ .custom-control-label::before,
.custom-control .custom-control-input:active ~ .custom-control-label::before {
  border-color: #02BC77; }

.custom-control .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 2px rgba(2, 188, 119, 0.4);
          box-shadow: 0 0 0 2px rgba(2, 188, 119, 0.4); }

.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #02BC77;
  background-color: #02BC77; }

.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E"); }

.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23fff'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23fff'/%3E%3C/svg%3E"); }

.custom-file-input:focus ~ .custom-file-label {
  border-color: #02BC77;
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }
  .custom-file-input:focus ~ .custom-file-label::after {
    border-color: inherit; }

.form-control:focus,
.custom-select:focus {
  border-color: #02BC77; }

.switcher-input:checked ~ .switcher-indicator {
  background: #02BC77;
  color: #fff; }

.switcher-input:focus ~ .switcher-indicator {
  -webkit-box-shadow: 0 0 0 2px rgba(2, 188, 119, 0.4);
          box-shadow: 0 0 0 2px rgba(2, 188, 119, 0.4); }

.switcher-input:active ~ .switcher-indicator {
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }

.navbar.bg-primary {
  background-color: #02BC77 !important;
  color: #cbf1e3; }
  .navbar.bg-primary .navbar-brand {
    color: #fff; }
    .navbar.bg-primary .navbar-brand:hover, .navbar.bg-primary .navbar-brand:focus {
      color: #fff; }
  .navbar.bg-primary .navbar-nav .nav-link {
    color: #cbf1e3; }
    .navbar.bg-primary .navbar-nav .nav-link:hover, .navbar.bg-primary .navbar-nav .nav-link:focus {
      color: #fff; }
    .navbar.bg-primary .navbar-nav .nav-link.disabled {
      color: #7bdcb8 !important; }
  .navbar.bg-primary .navbar-nav .show > .nav-link,
  .navbar.bg-primary .navbar-nav .active > .nav-link,
  .navbar.bg-primary .navbar-nav .nav-link.show,
  .navbar.bg-primary .navbar-nav .nav-link.active {
    color: #fff; }
  .navbar.bg-primary .navbar-toggler {
    color: #cbf1e3;
    border-color: rgba(255, 255, 255, 0.15); }
  .navbar.bg-primary .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
  .navbar.bg-primary .navbar-text {
    color: #cbf1e3; }
    .navbar.bg-primary .navbar-text a {
      color: #fff; }
      .navbar.bg-primary .navbar-text a:hover, .navbar.bg-primary .navbar-text a:focus {
        color: #fff; }
  .navbar.bg-primary hr {
    border-color: rgba(255, 255, 255, 0.15); }

.sidenav.bg-primary {
  background-color: #02BC77 !important;
  color: #cbf1e3; }
  .sidenav.bg-primary .sidenav-link,
  .sidenav.bg-primary .sidenav-horizontal-prev,
  .sidenav.bg-primary .sidenav-horizontal-next {
    color: #cbf1e3; }
    .sidenav.bg-primary .sidenav-link:hover, .sidenav.bg-primary .sidenav-link:focus,
    .sidenav.bg-primary .sidenav-horizontal-prev:hover,
    .sidenav.bg-primary .sidenav-horizontal-prev:focus,
    .sidenav.bg-primary .sidenav-horizontal-next:hover,
    .sidenav.bg-primary .sidenav-horizontal-next:focus {
      color: #fff; }
    .sidenav.bg-primary .sidenav-link.active,
    .sidenav.bg-primary .sidenav-horizontal-prev.active,
    .sidenav.bg-primary .sidenav-horizontal-next.active {
      color: #fff; }
  .sidenav.bg-primary .sidenav-item.disabled .sidenav-link,
  .sidenav.bg-primary .sidenav-horizontal-prev.disabled,
  .sidenav.bg-primary .sidenav-horizontal-next.disabled {
    color: #7bdcb8 !important; }
  .sidenav.bg-primary .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
  .sidenav.bg-primary .sidenav-item.active > .sidenav-link {
    color: #fff; }
  .sidenav.bg-primary .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #02b371; }
  .sidenav.bg-primary.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #02aa6b; }
  .sidenav.bg-primary.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
  .sidenav.bg-primary.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
    background: #02b371; }
  .sidenav.bg-primary .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
  .sidenav.bg-primary .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
    background: transparent;
    color: #cbf1e3; }
  .sidenav.bg-primary .sidenav-text {
    color: #fff; }
  .sidenav.bg-primary .sidenav-header {
    color: #99e4c8; }
  .sidenav.bg-primary hr,
  .sidenav.bg-primary .sidenav-divider,
  .sidenav.bg-primary .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    border-color: rgba(255, 255, 255, 0.15) !important; }
  .sidenav.bg-primary .sidenav-inner > .sidenav-header::before,
  .sidenav.bg-primary .sidenav-block::before {
    background-color: rgba(255, 255, 255, 0.15); }
  .sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
    background-color: #28be86; }
  .sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    background-color: #fff; }
  .sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
  .sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    -webkit-box-shadow: 0 0 0 2px #02b371;
            box-shadow: 0 0 0 2px #02b371; }
  .sidenav.bg-primary .ps__thumb-y,
  .sidenav.bg-primary .ps__rail-y.ps--clicking > .ps__thumb-y {
    background: rgba(255, 255, 255, 0.595325) !important; }

.footer.bg-primary {
  background-color: #02BC77 !important;
  color: #cbf1e3; }
  .footer.bg-primary .footer-link {
    color: #cbf1e3; }
    .footer.bg-primary .footer-link:hover, .footer.bg-primary .footer-link:focus {
      color: #fff; }
    .footer.bg-primary .footer-link.disabled {
      color: #7bdcb8 !important; }
  .footer.bg-primary .footer-text {
    color: #fff; }
  .footer.bg-primary .show > .footer-link,
  .footer.bg-primary .active > .footer-link,
  .footer.bg-primary .footer-link.show,
  .footer.bg-primary .footer-link.active {
    color: #fff; }
  .footer.bg-primary hr {
    border-color: rgba(255, 255, 255, 0.15); }

.fill-primary {
  fill: #02BC77; }

.slider-primary .slider-selection,
.slider-primary .slider-reversed .slider-track-high,
.slider-primary.slider-reversed .slider-track-high {
  background: #02BC77; }

.noUi-primary .noUi-base .noUi-connect {
  background: #02BC77 !important; }

.default-style .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #02BC77 !important;
  color: #fff !important; }

.default-style .select2-container--default.select2-container--focus .select2-selection,
.default-style .select2-container--default.select2-container--open .select2-selection {
  border-color: #02BC77 !important; }

.default-style .select2-primary .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: #02BC77;
  color: #fff; }

.bootstrap-tagsinput.focus {
  border-color: #02BC77 !important; }

.bootstrap-select .dropdown-menu.inner a[aria-selected=true] {
  background: #02BC77 !important;
  color: #fff !important; }

.datepicker table tr td.active, .datepicker table tr td.active.highlighted, .datepicker table tr td.active.today,
.datepicker table tr td span.active,
.datepicker table tr td span.active.disabled, .datepicker table tr td.range-start, .datepicker table tr td.range-end {
  background: #02BC77 !important;
  color: #fff !important; }

.datepicker table tr td.range, .datepicker table tr td.range.highlighted, .datepicker table tr td.range.today {
  background: #d9f5eb !important;
  color: #57625e !important; }
  .datepicker table tr td.range.focused, .datepicker table tr td.range.highlighted.focused, .datepicker table tr td.range.today.focused {
    background: #c1efde !important; }
  .datepicker table tr td.range.disabled, .datepicker table tr td.range.highlighted.disabled, .datepicker table tr td.range.today.disabled {
    background: rgba(217, 245, 235, 0.5) !important;
    color: rgba(87, 98, 94, 0.5) !important; }

.daterangepicker td.active {
  background-color: #02BC77 !important;
  color: #fff !important; }

.daterangepicker td.in-range:not(.start-date):not(.end-date):not(.off) {
  background-color: #d9f5eb !important;
  color: #57625e !important; }

.daterangepicker .input-mini.active {
  border-color: #02BC77 !important; }

.ranges li.active {
  background-color: #02BC77 !important;
  color: #fff !important; }

.dtp-date,
.dtp-time {
  background: #02BC77;
  color: #fff; }

.dtp-close > a {
  color: #fff; }

header.dtp-header {
  background: #02a065;
  color: #fff; }

table.dtp-picker-days tr > td > a.selected {
  background: #02BC77;
  color: #fff !important; }

.dtp-actual-meridien a.selected {
  background: #02BC77 !important;
  color: #fff !important; }

.dtp .year-picker-item:hover {
  color: #02BC77; }

.dtp .year-picker-item.active {
  color: #02BC77; }

.dtp .svg-clock [stroke="#8BC34A"] {
  stroke: #02BC77 !important; }

.dtp .svg-clock [fill="#8BC34A"] {
  fill: #02BC77 !important; }

.dtp .svg-clock [fill="#fff"] {
  fill: #fff !important; }

li.ui-timepicker-selected,
.ui-timepicker-list .ui-timepicker-selected:hover {
  background: #02BC77 !important;
  color: #fff !important; }

.growl-default {
  background: #02BC77 !important;
  color: #fff !important; }

.md-editor.active {
  border-color: #02BC77 !important; }

.ql-snow.ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar button:hover,
.ql-snow .ql-toolbar button:focus,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #02BC77 !important; }

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #02BC77 !important; }

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #02BC77 !important; }

.tt-suggestion:active,
.tt-cursor {
  background: #02BC77 !important;
  color: #fff !important; }

.dropzone.dz-drag-hover {
  border-color: #02BC77 !important; }

.swiper-pagination-bullet.swiper-pagination-bullet-active,
.swiper-pagination.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #02BC77 !important; }

.sk-primary.sk-rotating-plane,
.sk-primary.sk-double-bounce .sk-child,
.sk-primary.sk-wave .sk-rect,
.sk-primary.sk-wandering-cubes .sk-cube,
.sk-primary.sk-spinner-pulse,
.sk-primary.sk-chasing-dots .sk-child,
.sk-primary.sk-three-bounce .sk-child,
.sk-primary.sk-circle .sk-child:before,
.sk-primary.sk-cube-grid .sk-cube,
.sk-primary.sk-fading-circle .sk-circle:before,
.sk-primary.sk-folding-cube .sk-cube:before {
  background-color: #02BC77; }

.sw-theme-default .step-anchor > li.active > a .sw-icon,
.sw-theme-default .step-anchor > li.active > a .sw-number {
  color: #02BC77;
  border-color: #02BC77; }

.plyr input[type='range']::-ms-fill-lower {
  background: #02BC77 !important; }

.plyr input[type='range']:active::-webkit-slider-thumb {
  background: #02BC77 !important; }

.plyr input[type='range']:active::-moz-range-thumb {
  background: #02BC77 !important; }

.plyr input[type='range']:active::-ms-thumb {
  background: #02BC77 !important; }

.plyr--video .plyr__controls button.tab-focus:focus,
.plyr--video .plyr__controls button:hover {
  background: #02BC77 !important;
  color: !important; }

.plyr--audio .plyr__controls button.tab-focus:focus,
.plyr--audio .plyr__controls button:hover {
  background: #02BC77 !important;
  color: !important; }

.plyr__play-large {
  background: #02BC77 !important;
  color: !important; }

.plyr__progress--played,
.plyr__volume--display {
  color: #02BC77 !important; }

.fc-event,
.fc-event-dot {
  background: rgba(2, 188, 119, 0.15) !important; }

.fc-highlight,
.fc-bgevent {
  background: #02BC77 !important; }

.ui-product-color.active {
  -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 4px #02BC77 !important;
          box-shadow: 0 0 0 2px #fff, 0 0 0 4px #02BC77 !important; }

.bg-navbar-theme {
  background-color: #fff !important;
  color: #a3a4a6; }
  .bg-navbar-theme .navbar-brand {
    color: #4E5155; }
    .bg-navbar-theme .navbar-brand:hover, .bg-navbar-theme .navbar-brand:focus {
      color: #4E5155; }
  .bg-navbar-theme .navbar-nav .nav-link {
    color: #a3a4a6; }
    .bg-navbar-theme .navbar-nav .nav-link:hover, .bg-navbar-theme .navbar-nav .nav-link:focus {
      color: #4E5155; }
    .bg-navbar-theme .navbar-nav .nav-link.disabled {
      color: #c8c8ca !important; }
  .bg-navbar-theme .navbar-nav .show > .nav-link,
  .bg-navbar-theme .navbar-nav .active > .nav-link,
  .bg-navbar-theme .navbar-nav .nav-link.show,
  .bg-navbar-theme .navbar-nav .nav-link.active {
    color: #4E5155; }
  .bg-navbar-theme .navbar-toggler {
    color: #a3a4a6;
    border-color: rgba(78, 81, 85, 0.075); }
  .bg-navbar-theme .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(24, 28, 33, 0.4)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
  .bg-navbar-theme .navbar-text {
    color: #a3a4a6; }
    .bg-navbar-theme .navbar-text a {
      color: #4E5155; }
      .bg-navbar-theme .navbar-text a:hover, .bg-navbar-theme .navbar-text a:focus {
        color: #4E5155; }
  .bg-navbar-theme hr {
    border-color: rgba(78, 81, 85, 0.075); }

.layout-navbar {
  -webkit-box-shadow: 0 1px 0 rgba(24, 28, 33, 0.04);
          box-shadow: 0 1px 0 rgba(24, 28, 33, 0.04); }

.bg-sidenav-theme {
  background-color: #2e323a !important;
  color: #838790; }
  .bg-sidenav-theme .sidenav-link,
  .bg-sidenav-theme .sidenav-horizontal-prev,
  .bg-sidenav-theme .sidenav-horizontal-next {
    color: #838790; }
    .bg-sidenav-theme .sidenav-link:hover, .bg-sidenav-theme .sidenav-link:focus,
    .bg-sidenav-theme .sidenav-horizontal-prev:hover,
    .bg-sidenav-theme .sidenav-horizontal-prev:focus,
    .bg-sidenav-theme .sidenav-horizontal-next:hover,
    .bg-sidenav-theme .sidenav-horizontal-next:focus {
      color: #fff; }
    .bg-sidenav-theme .sidenav-link.active,
    .bg-sidenav-theme .sidenav-horizontal-prev.active,
    .bg-sidenav-theme .sidenav-horizontal-next.active {
      color: #fff; }
  .bg-sidenav-theme .sidenav-item.disabled .sidenav-link,
  .bg-sidenav-theme .sidenav-horizontal-prev.disabled,
  .bg-sidenav-theme .sidenav-horizontal-next.disabled {
    color: #61656e !important; }
  .bg-sidenav-theme .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
  .bg-sidenav-theme .sidenav-item.active > .sidenav-link {
    color: #fff; }
  .bg-sidenav-theme .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #383c44; }
  .bg-sidenav-theme.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: #42464d; }
  .bg-sidenav-theme.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu,
  .bg-sidenav-theme.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
    background: #383c44; }
  .bg-sidenav-theme .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
  .bg-sidenav-theme .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
    background: transparent;
    color: #838790; }
  .bg-sidenav-theme .sidenav-text {
    color: #fff; }
  .bg-sidenav-theme .sidenav-header {
    color: #6e727b; }
  .bg-sidenav-theme hr,
  .bg-sidenav-theme .sidenav-divider,
  .bg-sidenav-theme .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    border-color: rgba(255, 255, 255, 0.06) !important; }
  .bg-sidenav-theme .sidenav-inner > .sidenav-header::before,
  .bg-sidenav-theme .sidenav-block::before {
    background-color: rgba(255, 255, 255, 0.06); }
  .bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
    background-color: #44484f; }
  .bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    background-color: #fff; }
  .bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
  .bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    -webkit-box-shadow: 0 0 0 2px #383c44;
            box-shadow: 0 0 0 2px #383c44; }
  .bg-sidenav-theme .ps__thumb-y,
  .bg-sidenav-theme .ps__rail-y.ps--clicking > .ps__thumb-y {
    background: rgba(255, 255, 255, 0.477986) !important; }

.bg-footer-theme {
  background-color: #fff !important;
  color: #a3a4a6; }
  .bg-footer-theme .footer-link {
    color: #a3a4a6; }
    .bg-footer-theme .footer-link:hover, .bg-footer-theme .footer-link:focus {
      color: #4E5155; }
    .bg-footer-theme .footer-link.disabled {
      color: #c8c8ca !important; }
  .bg-footer-theme .footer-text {
    color: #4E5155; }
  .bg-footer-theme .show > .footer-link,
  .bg-footer-theme .active > .footer-link,
  .bg-footer-theme .footer-link.show,
  .bg-footer-theme .footer-link.active {
    color: #4E5155; }
  .bg-footer-theme hr {
    border-color: rgba(78, 81, 85, 0.075); }
