.hr-text {
  color: #000;
  text-align: center;
  height: 1.5em;
  opacity: .6;
  border: 0;
  outline: 0;
  font-size: .75rem;
  line-height: 1em;
  position: relative;
}

.hr-text:before {
  content: "";
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(#181c2199), to(transparent) );
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #0000, #181c2199, #0000);
  position: absolute;
  top: 50%;
  left: 0;
}

.hr-text:after {
  content: attr(data-content);
  background-color: #fff;
  padding: 0 .5em;
  line-height: 1.5em;
  display: inline-block;
  position: relative;
}

#card-body {
  height: 100vh;
  overflow-y: auto;
}

div.media-body {
  font-size: .85rem !important;
  line-height: 1.25rem !important;
}

table.chit-table th:last-child, table.chit-table td:last-child, table.chit-table.edit th:last-child, table.chit-table.edit td:last-child {
  text-align: right;
}

table.chit-table.edit td:nth-child(1) {
  width: 60%;
}

table.chit-table.edit td:nth-child(2) {
  width: 30%;
}

table.chit-table.edit td:nth-child(3) {
  width: 10%;
}

table.chit-table.edit th:nth-child(2), table.chit-table.edit td:nth-child(2) {
  text-align: right;
}

.bg-lighter td {
  font-weight: bold !important;
}

tr.chit-totals {
  background-color: inherit;
  font-size: 110%;
  font-weight: bold;
}

.form-label.hide {
  display: none !important;
}

body {
  background: #f8f8f8;
}

.text-primary {
  color: #02bc77 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #018a57 !important;
}

.bg-primary {
  background-color: #02bc77 !important;
}

a.bg-primary:hover, a.bg-primary:focus {
  background-color: #02b371 !important;
}

.bg-primary-dark {
  background-color: #02a96b !important;
}

a.bg-primary-dark:hover, a.bg-primary-dark:focus {
  background-color: #02a166 !important;
}

.bg-primary-darker {
  background-color: #02a065 !important;
}

a.bg-primary-darker:hover, a.bg-primary-darker:focus {
  background-color: #029860 !important;
}

html:not([dir="rtl"]) .border-primary, html[dir="rtl"] .border-primary {
  border-color: #02bc77 !important;
}

.badge-primary {
  color: #fff;
  background-color: #02bc77;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  background-color: #02b371;
  text-decoration: none;
}

.btn .badge-primary {
  color: #fff !important;
  background-color: #02bc77 !important;
}

.badge-outline-primary {
  color: #02bc77;
  background-color: #0000;
  box-shadow: inset 0 0 0 1px #02bc77;
}

.badge-outline-primary[href]:hover, .badge-outline-primary[href]:focus {
  color: #02bc77;
  text-decoration: none;
}

.btn .badge-outline-primary {
  color: #02bc77 !important;
  background-color: #0000 !important;
  box-shadow: inset 0 0 0 1px #02bc77 !important;
}

.page-item.active .page-link, .page-item.active .page-link:hover, .page-item.active .page-link:focus, .pagination li.active > a:not(.page-link), .pagination li.active > a:not(.page-link):hover, .pagination li.active > a:not(.page-link):focus {
  color: #fff !important;
  background-color: #02bc77 !important;
  border-color: #02bc77 !important;
}

.progress-bar {
  color: #fff;
  background-color: #02bc77;
}

.list-group-item-primary {
  color: #5c6360;
  background-color: #e6f8f1;
  border-color: #00000012;
}

a.list-group-item-primary, button.list-group-item-primary {
  color: #5c6360;
}

a.list-group-item-primary:hover, a.list-group-item-primary:focus, button.list-group-item-primary:hover, button.list-group-item-primary:focus {
  color: #5c6360;
  background-color: #e4f6ef;
}

a.list-group-item-primary.active, button.list-group-item-primary.active {
  color: #5c6360;
  background-color: #02bc77;
  border-color: #02bc77;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  color: #fff;
  background-color: #02bc77;
  border-color: #02bc77;
}

.alert-primary {
  color: #57625e;
  background-color: #d9f5eb;
  border-color: #cce6dd;
}

.alert-primary hr {
  border-top-color: #cce6dd;
}

.alert-primary .close, .alert-primary .alert-link {
  color: #57625e;
}

.alert-dark-primary {
  color: #fff;
  background-color: #02bc77;
}

.alert-dark-primary hr {
  border-top-color: #fff3;
}

.alert-dark-primary .close, .alert-dark-primary .alert-link {
  color: #fff;
}

.tooltip-primary .tooltip-inner, .tooltip-primary > .tooltip .tooltip-inner, .ngb-tooltip-primary + ngb-tooltip-window .tooltip-inner {
  color: #fff;
  background: #02bc77;
}

.tooltip-primary.bs-tooltip-top .arrow:before, .tooltip-primary.bs-tooltip-auto[x-placement^="top"] .arrow:before, .tooltip-primary > .tooltip.bs-tooltip-top .arrow:before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow:before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-top .arrow:before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #02bc77;
}

.tooltip-primary.bs-tooltip-right .arrow:before, .tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow:before, .tooltip-primary > .tooltip.bs-tooltip-right .arrow:before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow:before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-right .arrow:before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #02bc77;
}

.tooltip-primary.bs-tooltip-bottom .arrow:before, .tooltip-primary.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .tooltip-primary > .tooltip.bs-tooltip-bottom .arrow:before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-bottom .arrow:before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #02bc77;
}

.tooltip-primary.bs-tooltip-left .arrow:before, .tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow:before, .tooltip-primary > .tooltip.bs-tooltip-left .arrow:before, .tooltip-primary > .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow:before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-left .arrow:before, .ngb-tooltip-primary + ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #02bc77;
}

.popover-primary, .popover-primary > .popover, .ngb-popover-primary + ngb-popover-window {
  background: #02bc77;
  border-color: #0000;
}

.popover-primary .popover-header, .popover-primary > .popover .popover-header, .ngb-popover-primary + ngb-popover-window .popover-header {
  color: #fff;
  background: none;
  border-color: #fff3;
}

.popover-primary .popover-body, .popover-primary > .popover .popover-body, .ngb-popover-primary + ngb-popover-window .popover-body {
  color: #fff;
  background: none;
}

.popover-primary .arrow:before, .popover-primary > .popover .arrow:before, .ngb-popover-primary + ngb-popover-window .arrow:before {
  border-color: #0000 !important;
}

.popover-primary.bs-popover-top .arrow:after, .popover-primary.bs-popover-auto[x-placement^="top"] .arrow:after, .popover-primary > .popover.bs-popover-top .arrow:after, .popover-primary > .popover.bs-popover-auto[x-placement^="top"] .arrow:after, .ngb-popover-primary + ngb-popover-window.bs-popover-top .arrow:after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="top"] .arrow:after {
  border-top-color: #02bc77;
}

.popover-primary.bs-popover-right .arrow:after, .popover-primary.bs-popover-auto[x-placement^="right"] .arrow:after, .popover-primary > .popover.bs-popover-right .arrow:after, .popover-primary > .popover.bs-popover-auto[x-placement^="right"] .arrow:after, .ngb-popover-primary + ngb-popover-window.bs-popover-right .arrow:after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="right"] .arrow:after {
  border-right-color: #02bc77;
}

.popover-primary.bs-popover-bottom .arrow:after, .popover-primary.bs-popover-auto[x-placement^="bottom"] .arrow:after, .popover-primary > .popover.bs-popover-bottom .arrow:after, .popover-primary > .popover.bs-popover-auto[x-placement^="bottom"] .arrow:after, .ngb-popover-primary + ngb-popover-window.bs-popover-bottom .arrow:after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="bottom"] .arrow:after {
  border-bottom-color: #02bc77;
}

.popover-primary.bs-popover-left .arrow:after, .popover-primary.bs-popover-auto[x-placement^="left"] .arrow:after, .popover-primary > .popover.bs-popover-left .arrow:after, .popover-primary > .popover.bs-popover-auto[x-placement^="left"] .arrow:after, .ngb-popover-primary + ngb-popover-window.bs-popover-left .arrow:after, .ngb-popover-primary + ngb-popover-window.bs-popover-auto[x-placement^="left"] .arrow:after {
  border-left-color: #02bc77;
}

.table .table-primary, .table .table-primary > th, .table .table-primary > td {
  color: #5c6360;
  background-color: #e6f8f1;
  border-color: #00000009;
}

.table-hover .table-primary:hover, .table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #e4f6ef;
}

.btn-primary {
  color: #fff;
  background: #02bc77;
  border-color: #0000;
}

.btn-primary:hover {
  color: #fff;
  background: #02b371;
  border-color: #0000;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 2px #02bc7766;
}

.btn-primary.disabled, .btn-primary:disabled {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #fff !important;
  background: #02bc77 !important;
  border-color: #0000 !important;
}

.btn-primary .badge {
  color: #02bc77;
  background: #fff;
}

.btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #02a468;
  border-color: #0000;
}

.btn-group .btn-primary, .input-group-prepend .btn-primary, .input-group-append .btn-primary {
  border-left: 1px solid #02a468;
  border-right: 1px solid #02a468;
}

.btn-outline-primary {
  color: #02bc77;
  background: none;
  border-color: #02bc77;
}

.btn-outline-primary:hover {
  color: #fff;
  background: #02bc77;
  border-color: #0000;
}

.btn-outline-primary:hover .badge {
  color: #02bc77;
  background: #fff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 2px #02bc7766;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #02bc77 !important;
  background: none !important;
  border-color: #02bc77 !important;
}

.btn-outline-primary .badge {
  color: #fff;
  background: #02bc77;
}

.btn-outline-primary:active, .btn-outline-primary.active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #02b371;
  border-color: #0000;
}

.btn-outline-primary:active .badge, .btn-outline-primary.active .badge, .show > .btn-outline-primary.dropdown-toggle .badge {
  color: #02bc77;
  background: #fff;
}

.dropdown-item:not(.disabled).active, .dropdown-item:not(.disabled):active, .dropdown-menu > li:not(.disabled) > a:not(.dropdown-item):active, .dropdown-menu > li.active:not(.disabled) > a:not(.dropdown-item), .nav-pills .nav-link.active, .nav-pills .nav-link.active:hover, .nav-pills .nav-link.active:focus {
  color: #fff;
  background-color: #02bc77;
}

.tabs-alt.nav-tabs .nav-link.active, .tabs-alt.nav-tabs .nav-link.active:hover, .tabs-alt.nav-tabs .nav-link.active:focus, .tabs-alt > .nav-tabs .nav-link.active, .tabs-alt > .nav-tabs .nav-link.active:hover, .tabs-alt > .nav-tabs .nav-link.active:focus {
  box-shadow: inset 0 -2px #02bc77;
}

.custom-control .custom-control-input:focus ~ .custom-control-label:before, .custom-control .custom-control-input:active ~ .custom-control-label:before {
  border-color: #02bc77;
}

.custom-control .custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 2px #02bc7766;
}

.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before, .custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #02bc77;
  border-color: #02bc77;
}

.custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
}

.custom-control.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='4.5' style='fill:%23fff'/%3E%3Cpath d='M5,1A4,4,0,1,1,1,5,4,4,0,0,1,5,1M5,0a5,5,0,1,0,5,5A5,5,0,0,0,5,0Z' style='fill:%23fff'/%3E%3C/svg%3E");
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #02bc77;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.custom-file-input:focus ~ .custom-file-label:after {
  border-color: inherit;
}

.form-control:focus, .custom-select:focus {
  border-color: #02bc77;
}

.switcher-input:checked ~ .switcher-indicator {
  color: #fff;
  background: #02bc77;
}

.switcher-input:focus ~ .switcher-indicator {
  box-shadow: 0 0 0 2px #02bc7766;
}

.switcher-input:active ~ .switcher-indicator {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.navbar.bg-primary {
  color: #cbf1e3;
  background-color: #02bc77 !important;
}

.navbar.bg-primary .navbar-brand, .navbar.bg-primary .navbar-brand:hover, .navbar.bg-primary .navbar-brand:focus {
  color: #fff;
}

.navbar.bg-primary .navbar-nav .nav-link {
  color: #cbf1e3;
}

.navbar.bg-primary .navbar-nav .nav-link:hover, .navbar.bg-primary .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar.bg-primary .navbar-nav .nav-link.disabled {
  color: #7bdcb8 !important;
}

.navbar.bg-primary .navbar-nav .show > .nav-link, .navbar.bg-primary .navbar-nav .active > .nav-link, .navbar.bg-primary .navbar-nav .nav-link.show, .navbar.bg-primary .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar.bg-primary .navbar-toggler {
  color: #cbf1e3;
  border-color: #ffffff26;
}

.navbar.bg-primary .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar.bg-primary .navbar-text {
  color: #cbf1e3;
}

.navbar.bg-primary .navbar-text a, .navbar.bg-primary .navbar-text a:hover, .navbar.bg-primary .navbar-text a:focus {
  color: #fff;
}

.navbar.bg-primary hr {
  border-color: #ffffff26;
}

.sidenav.bg-primary {
  color: #cbf1e3;
  background-color: #02bc77 !important;
}

.sidenav.bg-primary .sidenav-link, .sidenav.bg-primary .sidenav-horizontal-prev, .sidenav.bg-primary .sidenav-horizontal-next {
  color: #cbf1e3;
}

.sidenav.bg-primary .sidenav-link:hover, .sidenav.bg-primary .sidenav-link:focus, .sidenav.bg-primary .sidenav-horizontal-prev:hover, .sidenav.bg-primary .sidenav-horizontal-prev:focus, .sidenav.bg-primary .sidenav-horizontal-next:hover, .sidenav.bg-primary .sidenav-horizontal-next:focus, .sidenav.bg-primary .sidenav-link.active, .sidenav.bg-primary .sidenav-horizontal-prev.active, .sidenav.bg-primary .sidenav-horizontal-next.active {
  color: #fff;
}

.sidenav.bg-primary .sidenav-item.disabled .sidenav-link, .sidenav.bg-primary .sidenav-horizontal-prev.disabled, .sidenav.bg-primary .sidenav-horizontal-next.disabled {
  color: #7bdcb8 !important;
}

.sidenav.bg-primary .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle, .sidenav.bg-primary .sidenav-item.active > .sidenav-link {
  color: #fff;
}

.sidenav.bg-primary .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #02b371;
}

.sidenav.bg-primary.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #02aa6b;
}

.sidenav.bg-primary.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu, .sidenav.bg-primary.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #02b371;
}

.sidenav.bg-primary .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu, .sidenav.bg-primary .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  color: #cbf1e3;
  background: none;
}

.sidenav.bg-primary .sidenav-text {
  color: #fff;
}

.sidenav.bg-primary .sidenav-header {
  color: #99e4c8;
}

.sidenav.bg-primary hr, .sidenav.bg-primary .sidenav-divider, .sidenav.bg-primary .sidenav-inner > .sidenav-item.open > .sidenav-menu:before {
  border-color: #ffffff26 !important;
}

.sidenav.bg-primary .sidenav-inner > .sidenav-header:before, .sidenav.bg-primary .sidenav-block:before {
  background-color: #ffffff26;
}

.sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle:before {
  background-color: #28be86;
}

.sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link:before {
  background-color: #fff;
}

.sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle:before, .sidenav.bg-primary .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link:before {
  box-shadow: 0 0 0 2px #02b371;
}

.sidenav.bg-primary .ps__thumb-y, .sidenav.bg-primary .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: #ffffff98 !important;
}

.footer.bg-primary {
  color: #cbf1e3;
  background-color: #02bc77 !important;
}

.footer.bg-primary .footer-link {
  color: #cbf1e3;
}

.footer.bg-primary .footer-link:hover, .footer.bg-primary .footer-link:focus {
  color: #fff;
}

.footer.bg-primary .footer-link.disabled {
  color: #7bdcb8 !important;
}

.footer.bg-primary .footer-text, .footer.bg-primary .show > .footer-link, .footer.bg-primary .active > .footer-link, .footer.bg-primary .footer-link.show, .footer.bg-primary .footer-link.active {
  color: #fff;
}

.footer.bg-primary hr {
  border-color: #ffffff26;
}

.fill-primary {
  fill: #02bc77;
}

.slider-primary .slider-selection, .slider-primary .slider-reversed .slider-track-high, .slider-primary.slider-reversed .slider-track-high {
  background: #02bc77;
}

.noUi-primary .noUi-base .noUi-connect {
  background: #02bc77 !important;
}

.default-style .select2-container--default .select2-results__option--highlighted[aria-selected] {
  color: #fff !important;
  background-color: #02bc77 !important;
}

.default-style .select2-container--default.select2-container--focus .select2-selection, .default-style .select2-container--default.select2-container--open .select2-selection {
  border-color: #02bc77 !important;
}

.default-style .select2-primary .select2-container--default .select2-selection--multiple .select2-selection__choice {
  color: #fff;
  background: #02bc77;
}

.bootstrap-tagsinput.focus {
  border-color: #02bc77 !important;
}

.bootstrap-select .dropdown-menu.inner a[aria-selected="true"], .datepicker table tr td.active, .datepicker table tr td.active.highlighted, .datepicker table tr td.active.today, .datepicker table tr td span.active, .datepicker table tr td span.active.disabled, .datepicker table tr td.range-start, .datepicker table tr td.range-end {
  color: #fff !important;
  background: #02bc77 !important;
}

.datepicker table tr td.range, .datepicker table tr td.range.highlighted, .datepicker table tr td.range.today {
  color: #57625e !important;
  background: #d9f5eb !important;
}

.datepicker table tr td.range.focused, .datepicker table tr td.range.highlighted.focused, .datepicker table tr td.range.today.focused {
  background: #c1efde !important;
}

.datepicker table tr td.range.disabled, .datepicker table tr td.range.highlighted.disabled, .datepicker table tr td.range.today.disabled {
  color: #57625e80 !important;
  background: #d9f5eb80 !important;
}

.daterangepicker td.active {
  color: #fff !important;
  background-color: #02bc77 !important;
}

.daterangepicker td.in-range:not(.start-date):not(.end-date):not(.off) {
  color: #57625e !important;
  background-color: #d9f5eb !important;
}

.daterangepicker .input-mini.active {
  border-color: #02bc77 !important;
}

.ranges li.active {
  color: #fff !important;
  background-color: #02bc77 !important;
}

.dtp-date, .dtp-time {
  color: #fff;
  background: #02bc77;
}

.dtp-close > a {
  color: #fff;
}

header.dtp-header {
  color: #fff;
  background: #02a065;
}

table.dtp-picker-days tr > td > a.selected {
  background: #02bc77;
  color: #fff !important;
}

.dtp-actual-meridien a.selected {
  color: #fff !important;
  background: #02bc77 !important;
}

.dtp .year-picker-item:hover, .dtp .year-picker-item.active {
  color: #02bc77;
}

.dtp .svg-clock [stroke="#8BC34A"] {
  stroke: #02bc77 !important;
}

.dtp .svg-clock [fill="#8BC34A"] {
  fill: #02bc77 !important;
}

.dtp .svg-clock [fill="#fff"] {
  fill: #fff !important;
}

li.ui-timepicker-selected, .ui-timepicker-list .ui-timepicker-selected:hover, .growl-default {
  color: #fff !important;
  background: #02bc77 !important;
}

.md-editor.active {
  border-color: #02bc77 !important;
}

.ql-snow.ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar button:hover, .ql-snow .ql-toolbar button:focus, .ql-snow .ql-toolbar button.ql-active, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #02bc77 !important;
}

.ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #02bc77 !important;
}

.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #02bc77 !important;
}

.tt-suggestion:active, .tt-cursor {
  color: #fff !important;
  background: #02bc77 !important;
}

.dropzone.dz-drag-hover {
  border-color: #02bc77 !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active, .swiper-pagination.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #02bc77 !important;
}

.sk-primary.sk-rotating-plane, .sk-primary.sk-double-bounce .sk-child, .sk-primary.sk-wave .sk-rect, .sk-primary.sk-wandering-cubes .sk-cube, .sk-primary.sk-spinner-pulse, .sk-primary.sk-chasing-dots .sk-child, .sk-primary.sk-three-bounce .sk-child, .sk-primary.sk-circle .sk-child:before, .sk-primary.sk-cube-grid .sk-cube, .sk-primary.sk-fading-circle .sk-circle:before, .sk-primary.sk-folding-cube .sk-cube:before {
  background-color: #02bc77;
}

.sw-theme-default .step-anchor > li.active > a .sw-icon, .sw-theme-default .step-anchor > li.active > a .sw-number {
  color: #02bc77;
  border-color: #02bc77;
}

.plyr input[type="range"]::-ms-fill-lower {
  background: #02bc77 !important;
}

.plyr input[type="range"]:active::-webkit-slider-thumb {
  background: #02bc77 !important;
}

.plyr input[type="range"]:active::-moz-range-thumb {
  background: #02bc77 !important;
}

.plyr input[type="range"]:active::-ms-thumb {
  background: #02bc77 !important;
}

.plyr--video .plyr__controls button.tab-focus:focus, .plyr--video .plyr__controls button:hover, .plyr--audio .plyr__controls button.tab-focus:focus, .plyr--audio .plyr__controls button:hover, .plyr__play-large {
  color:  !important;
  background: #02bc77 !important;
}

.plyr__progress--played, .plyr__volume--display {
  color: #02bc77 !important;
}

.fc-event, .fc-event-dot {
  background: #02bc7726 !important;
}

.fc-highlight, .fc-bgevent {
  background: #02bc77 !important;
}

.ui-product-color.active {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #02bc77 !important;
}

.bg-navbar-theme {
  color: #a3a4a6;
  background-color: #fff !important;
}

.bg-navbar-theme .navbar-brand, .bg-navbar-theme .navbar-brand:hover, .bg-navbar-theme .navbar-brand:focus {
  color: #4e5155;
}

.bg-navbar-theme .navbar-nav .nav-link {
  color: #a3a4a6;
}

.bg-navbar-theme .navbar-nav .nav-link:hover, .bg-navbar-theme .navbar-nav .nav-link:focus {
  color: #4e5155;
}

.bg-navbar-theme .navbar-nav .nav-link.disabled {
  color: #c8c8ca !important;
}

.bg-navbar-theme .navbar-nav .show > .nav-link, .bg-navbar-theme .navbar-nav .active > .nav-link, .bg-navbar-theme .navbar-nav .nav-link.show, .bg-navbar-theme .navbar-nav .nav-link.active {
  color: #4e5155;
}

.bg-navbar-theme .navbar-toggler {
  color: #a3a4a6;
  border-color: #4e515513;
}

.bg-navbar-theme .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(24, 28, 33, 0.4)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.bg-navbar-theme .navbar-text {
  color: #a3a4a6;
}

.bg-navbar-theme .navbar-text a, .bg-navbar-theme .navbar-text a:hover, .bg-navbar-theme .navbar-text a:focus {
  color: #4e5155;
}

.bg-navbar-theme hr {
  border-color: #4e515513;
}

.layout-navbar {
  box-shadow: 0 1px #181c210a;
}

.bg-sidenav-theme {
  color: #838790;
  background-color: #2e323a !important;
}

.bg-sidenav-theme .sidenav-link, .bg-sidenav-theme .sidenav-horizontal-prev, .bg-sidenav-theme .sidenav-horizontal-next {
  color: #838790;
}

.bg-sidenav-theme .sidenav-link:hover, .bg-sidenav-theme .sidenav-link:focus, .bg-sidenav-theme .sidenav-horizontal-prev:hover, .bg-sidenav-theme .sidenav-horizontal-prev:focus, .bg-sidenav-theme .sidenav-horizontal-next:hover, .bg-sidenav-theme .sidenav-horizontal-next:focus, .bg-sidenav-theme .sidenav-link.active, .bg-sidenav-theme .sidenav-horizontal-prev.active, .bg-sidenav-theme .sidenav-horizontal-next.active {
  color: #fff;
}

.bg-sidenav-theme .sidenav-item.disabled .sidenav-link, .bg-sidenav-theme .sidenav-horizontal-prev.disabled, .bg-sidenav-theme .sidenav-horizontal-next.disabled {
  color: #61656e !important;
}

.bg-sidenav-theme .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle, .bg-sidenav-theme .sidenav-item.active > .sidenav-link {
  color: #fff;
}

.bg-sidenav-theme .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #383c44;
}

.bg-sidenav-theme.sidenav-horizontal .sidenav-menu > .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: #42464d;
}

.bg-sidenav-theme.sidenav-horizontal .sidenav-inner > .sidenav-item:not(.sidenav-item-closing) > .sidenav-menu, .bg-sidenav-theme.sidenav-horizontal .sidenav-inner > .sidenav-item.open > .sidenav-toggle {
  background: #383c44;
}

.bg-sidenav-theme .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu, .bg-sidenav-theme .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
  color: #838790;
  background: none;
}

.bg-sidenav-theme .sidenav-text {
  color: #fff;
}

.bg-sidenav-theme .sidenav-header {
  color: #6e727b;
}

.bg-sidenav-theme hr, .bg-sidenav-theme .sidenav-divider, .bg-sidenav-theme .sidenav-inner > .sidenav-item.open > .sidenav-menu:before {
  border-color: #ffffff0f !important;
}

.bg-sidenav-theme .sidenav-inner > .sidenav-header:before, .bg-sidenav-theme .sidenav-block:before {
  background-color: #ffffff0f;
}

.bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle:before {
  background-color: #44484f;
}

.bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link:before {
  background-color: #fff;
}

.bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle:before, .bg-sidenav-theme .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link:before {
  box-shadow: 0 0 0 2px #383c44;
}

.bg-sidenav-theme .ps__thumb-y, .bg-sidenav-theme .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: #ffffff7a !important;
}

.bg-footer-theme {
  color: #a3a4a6;
  background-color: #fff !important;
}

.bg-footer-theme .footer-link {
  color: #a3a4a6;
}

.bg-footer-theme .footer-link:hover, .bg-footer-theme .footer-link:focus {
  color: #4e5155;
}

.bg-footer-theme .footer-link.disabled {
  color: #c8c8ca !important;
}

.bg-footer-theme .footer-text, .bg-footer-theme .show > .footer-link, .bg-footer-theme .active > .footer-link, .bg-footer-theme .footer-link.show, .bg-footer-theme .footer-link.active {
  color: #4e5155;
}

.bg-footer-theme hr {
  border-color: #4e515513;
}

/*# sourceMappingURL=index.1e6a0185.css.map */
